// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-templates-blog-post-js": () => import("/opt/build/repo/src/templates/blog-post.js" /* webpackChunkName: "component---src-templates-blog-post-js" */),
  "component---src-pages-about-js": () => import("/opt/build/repo/src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-account-js": () => import("/opt/build/repo/src/pages/account.js" /* webpackChunkName: "component---src-pages-account-js" */),
  "component---src-pages-cb-js": () => import("/opt/build/repo/src/pages/cb.js" /* webpackChunkName: "component---src-pages-cb-js" */),
  "component---src-pages-index-js": () => import("/opt/build/repo/src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-my-files-js": () => import("/opt/build/repo/src/pages/my-files.js" /* webpackChunkName: "component---src-pages-my-files-js" */),
  "component---src-pages-posts-index-js": () => import("/opt/build/repo/src/pages/posts/index.js" /* webpackChunkName: "component---src-pages-posts-index-js" */)
}

